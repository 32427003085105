import React, { createContext, useContext, useState, useEffect } from 'react';
import { lightTheme, darkTheme } from 'styles/theme';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const getPreferredTheme = () =>
        localStorage.getItem('theme') || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

    const [theme, setTheme] = useState(getPreferredTheme());
    const colors = theme === 'dark' ? darkTheme : lightTheme;

    const toggleTheme = () => {
        setTheme((prev) => (prev === 'dark' ? 'light' : 'dark'));
    };

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);

        Object.keys(colors).forEach((key) => {
            document.documentElement.style.setProperty(`--${key}`, colors[key]);
        });

        localStorage.setItem('theme', theme);
    }, [theme, colors]);

    return (
        <ThemeContext.Provider
            value={{
                theme,
                colors,
                setTheme,
                toggleTheme,
                isDark: theme === 'dark',
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);

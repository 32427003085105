import React from 'react';
import 'styles/main-layout.scss';
import { ArrowLeftIcon } from '@/assets/icons/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import Footer from '@/components/layout/Footer';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

const topLevelRoutes = ['/', '/login']; // 앱의 주요 루트

export default function MainLayout({ children }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const companyName = useSelector((state) => state.company.companyInfo?.name); // Redux에서 로고 가져오기

    const goBack = () => {
        navigate(-1);
    };

    const showBackButton = !topLevelRoutes.includes(pathname);

    return (
        <div className="layout-container">
            <div className="layout">
                {showBackButton && (
                    <div className="main-header">
                        <div onClick={goBack}>
                            <ArrowLeftIcon color="var(--textPrimary)" />
                        </div>
                        <div className="company-name">{companyName}</div>
                        <div className="right-placeholder" />
                    </div>
                )}
                <div className={classNames('main-content', { 'exist-header': showBackButton })}>{children}</div>
                {/* {pathname !== '/login' && <Footer />} */}
            </div>
        </div>
    );
}

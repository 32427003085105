import React from 'react';

export default function CustomText({
    children,
    darkColor = false,
    lightColor = false,
    semiBold = false,
    bold = false,
    medium = false,
    color = '',
    fontSize = 'body5',
    fontWeight = 'normal',
    lineHeight = '20px',
    opacity = 1,
    right,
    center = false,
    style = {},
    ellipsis = false,
    lineClamp = 1,
    warpClassName = '',
    ...rest
}) {
    const size = {
        huge: 35,
        h1: 24,
        h2: 20,
        body1: 18,
        body2: 17,
        body3: 16,
        body4: 15,
        body5: 14,
        body6: 13,
        body7: 12,
        body8: 10,
    };

    const styleValue = {
        opacity: Number(opacity),
        textAlign: center ? 'center' : right ? 'right' : 'left',
        color: color || 'var(--textBase)',
        fontSize: size[fontSize] || 15,
        cursor: 'default',
        ...style,
    };

    const ellipsisStyle = {
        whiteSpace: lineClamp > 1 ? 'normal' : 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: lineClamp,
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
    };

    return (
        <div
            className={warpClassName}
            style={
                ellipsis ? { display: 'inline-flex', ...ellipsisStyle, ...style } : { display: 'inline-flex', ...style }
            }
        >
            <span
                style={{
                    color: color || 'var(--textBase)',
                    ...(bold && {
                        fontFamily: 'Pretendard-Bold',
                        fontWeight: 700,
                    }),
                    ...(!bold &&
                        semiBold && {
                            fontFamily: 'Pretendard-SemiBold',
                            fontWeight: 600,
                        }),
                    ...(!bold &&
                        !semiBold &&
                        medium && {
                            fontFamily: 'Pretendard-Medium',
                            fontWeight: 500,
                        }),
                    ...(!bold &&
                        !semiBold &&
                        !medium && {
                            fontFamily: 'Pretendard-Regular',
                            fontWeight: 400,
                        }),
                    ...styleValue,
                }}
                {...rest}
            >
                {children}
            </span>
        </div>
    );
}

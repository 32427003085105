const gray = {
    gray50: '#f9fafb',
    gray100: '#f2f4f6',
    gray200: '#e5e8eb',
    gray300: '#d1d6db',
    gray400: '#b0b8c1',
    gray500: '#8b95a1',
    gray600: '#6b7684',
    gray700: '#4e5968',
    gray800: '#333d4b',
    gray900: '#191f28',
};

const blue = {
    blue50: '#ECEEFF',
    blue100: '#D9DEFF',
    blue200: '#BFC7F6',
    blue300: '#A5B1ED',
    blue400: '#8B9BE3',
    blue500: '#6673BB',
    blue600: '#535D9B',
    blue700: '#40477B',
    blue800: '#2D315B',
    blue900: '#1A1B3B',
};

const red = {
    red50: '#FFECEC',
    red100: '#FFD9D9',
    red200: '#F6BFC7',
    red300: '#EDA5B1',
    red400: '#E38B9B',
    red500: '#BB6673',
    red600: '#9B535D',
    red700: '#7B4047',
    red800: '#5B2D31',
    red900: '#3B1A1B',
};

const green = {
    green50: '#ECFFEC',
    green100: '#D9FFD9',
    green200: '#C7F6BF',
    green300: '#B1EDA5',
    green400: '#9BE38B',
    green500: '#73BB66',
    green600: '#5D9B53',
    green700: '#478040',
    green800: '#315B2D',
    green900: '#1B3B1A',
};

const common = {
    blueText: '#4184FC',
    redText: '#DC4A43',
};

export const lightTheme = {
    themeColor: '#FFFFFF',
    reverseThemeColor: 'var(--gray900)',
    textBase: '#000000',
    textInactive: '#999999',
    borderBase: '#DADADA',
    reverseBackgroundBase: '#000000',
    reverseTextBase: '#FFFFFF',

    background: '#FFFFFF',
    backgroundBase: '#f1f1f1',
    backgroundSecondary: '#f7f7f7',
    backgroundItemHover: 'rgba(0, 0, 0, 0.1)',
    textComment: '#777777',

    textPrimary: 'var(--gray900)',
    textSecondary: 'var(--gray800)',
    textTertiary: 'var(--gray700)',
    textQuaternary: 'var(--gray600)',
    textDisabled: 'var(--gray500)',

    backgroundPrimary: 'var(--gray50)',
    backgroundTertiary: 'var(--gray200)',

    borderPrimary: 'var(--gray300)',
    borderSecondary: 'var(--gray200)',
    borderTertiary: 'var(--gray100)',

    ...common,
    ...gray,
    ...blue,
    ...red,
    ...green,
};

export const darkTheme = {
    themeColor: 'var(--gray900)',
    reverseThemeColor: '#FFFFFF',
    textBase: '#fff',
    reverseBackgroundBase: '#FFFFFF',
    reverseTextBase: '#000000',
    textPrimary: 'var(--gray50)',
    textSecondary: 'var(--gray100)',
    textTertiary: 'var(--gray200)',
    textQuaternary: 'var(--gray300)',
    textDisabled: 'var(--gray400)',

    background: '#1C1C1E',
    backgroundBase: '#717171',
    backgroundSecondary: '#2B2C2E',
    backgroundTertiary: 'var(--gray200)',

    textInactive: '#A3A3A3',
    textComment: '#949399',
    textSecondaryDark: '#C06905',
    textWarn: '#FB0000',
    textReverse: '#000000',
    backgroundButtonHover: '#252527',
    backgroundHeader: '#212025',
    backgroundPrimary: '#153b60',
    backgroundPrimary50: 'rgba(21, 59, 96, 0.5)',
    backgroundItemHover: 'rgba(255, 255, 255, 0.1)',

    backgroundBlue: '#0b84ff',
    backgroundOrange: '#FF9A00',
    backgroundOrangeDark: '#2E231D',
    backgroundBlueDark: 'rgba(21, 59, 96, 0.49)',
    backgroundRedDark: '#2C1D1F',

    borderPrimary: 'var(--gray600)',
    borderSecondary: 'var(--gray700)',
    borderTertiary: 'var(--gray800)',

    ...common,
    ...gray,
    ...blue,
    ...red,
    ...green,
};
